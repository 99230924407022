import api from "../config/api.js";
async function quickFetch(endpoint,arrayname=null) {
    const response = await api.get(endpoint);
    const json = await response.data[arrayname];
    return json;
  }

  async function quickFetch2(endpoint,arrayname=null,id) {
    // console.log('from service api quick fetch 2')
        // console.log(id)
        // console.log(arrayname);
        // console.log(endpoint)
      const response = await api.get(endpoint+id);
      const json = await response.data[arrayname];
      // json=json[arrayname];
      return json;
    }
  
  async function quicksubmit(params) {
    const response = await api.post(params.endpoint,params);
    const json = await response.data.message;
    return json;
  }

  export default function makeService(endpoint,arrayname=null) {
    return {
      async find(id) {
        return quickFetch(`${endpoint}/${id}`);
      },
      async list() {
        return quickFetch(endpoint,arrayname);
      },
      async list2(id,endpoint2 = null) {
        if(endpoint2){
          return quickFetch2('/admin/course-cat-courses?course_cat_id=', arrayname, id);
        }
        return quickFetch2(endpoint,arrayname,id);
      },
      async submit(params){
        return quicksubmit(params)
      },
    };
  }