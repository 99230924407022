const baseURL = process.env.VUE_APP_API_URL;



//import { store } from "../store/store";

import axios from "axios";
import { store } from "../Store/index.js"
// Create axios client, pre-configured with baseURL
let API = axios.create({ baseURL });

// Add a request interceptor
API.interceptors.request.use(
    function (config) {
        let token = localStorage.getItem("token");
        if (!token) delete config.headers["Authorization"];
        else config.headers["Authorization"] = `Bearer ${token}`;

        // Do something before request is sent(this is for loader display)
        store.state.isLoading = true;
        // console.log('function 1')
        return config;
    },
    function (error) {
        // Do something with request error
        store.state.isLoading = false;
        // console.log('function 2')
        return Promise.reject(error);
    }
);

// Add a response interceptor

// Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

API.interceptors.response.use(
    function (response) {
        store.state.isLoading = false;
        // console.log('function 3')
        return response;
    },
    function (error) {
        store.state.isLoading = false;
        // console.log('function 4')
        return Promise.reject(error);
    }
);


// export const setClientToken = (token) => {
//     if (!token) delete API.defaults.headers.common["Authorization"];
//     else API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// };



export default API;