import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ListingContainer from "@/components/Audios/AudioCategoriesListingContainer.vue";
import AudioListingContainer from "@/components/Audios/AudioListingContainer.vue";

import MBListingContainer from "@/components/Meditation-other-content/MBListingContainer";
import MTListingContainer from "@/components/Meditation-other-content/MTListingContainer.vue";

import SeriesCategoriesListingContainer from "@/components/Series/SeriesCategoriesListingContainer.vue";
import VideoCategoriesListingContainer from "@/components/Video/VideoCategoriesListingContainer.vue";

import SCListingContainer from "@/components/Scripts/ScriptCategoriesListingContainer.vue";
import CCListingContainer from "@/components/Courses/CCListingContainer.vue";
import EventListingContainer from "@/components/Events/EventListingContainer.vue";
import CourseListingContainer from "@/components/Courses/CourseListingContainer.vue";
import ModuleListingContainer from "@/components/Courses/ModuleListingContainer.vue";
import ContentListingContainer from "@/components/Courses/ContentListingContainer.vue";

import ScriptListingContainer from "@/components/Scripts/ScriptListingContainer.vue";
import SeriesListingContainer from "@/components/Series/SeriesListingContainer.vue";
import VideoListingContainer from "@/components/Video/VideoListingContainer";
import EbookListingContainer from "@/components/Ebooks/EbookListingContainer.vue";
import NotificationListingContainer from "@/components/Notifications/NotificationListingContainer.vue";
import TagListingContainer from "@/components/Tags/TagListingContainer.vue";
import GoalListingContainer from "@/components/Goals/GoalListingContainer.vue";
import FeelingListingContainer from "@/components/Feelings/FeelingListingContainer.vue";
import UserListingContainer from "@/components/Users/UserListingContainer.vue";
import UserPaidListingContainer from "@/components/Users/UserPaidListingContainer.vue";
import UserPromoListingContainer from "@/components/Users/UserPromoListingContainer.vue";
import UserFreeListingContainer from "@/components/Users/UserFreeListingContainer.vue";
import PromoListingContainer from "@/components/Promocodes/PromoListingContainer.vue";

import config from "./data/audio-categories";
import config_audios from "./data/audio";
import config_mb from "./data/meditation-background";
import config_mt from "./data/meditation-tune";
import config_script_categories from "./data/script-categories";
import config_series_categories from "./data/series-categories";
import config_video_categories from "./data/video-categories";
import config_course_categories from "./data/course-categories";
import config_event from "./data/event";
import config_course from "./data/course";
import config_module from "./data/module";
import config_content from "./data/content";
import config_script from "./data/script";
import config_series from "./data/series";
import config_video from "./data/video";
import config_ebook from "./data/ebook";
import config_notification from "./data/notification";
import config_tag from "./data/tag";
import config_goal from "./data/goal";
import config_feeling from "./data/feeling";
import config_user from "./data/user";
import config_user_paid from "./data/user_paid.json";
import config_user_promo from "./data/user_promo.json";
import config_user_free from "./data/user_free.json";
import config_promo from "./data/promocode";
// import layout from '@/layout'



import makeCrudModule from "./Store/crud";

import makeCrudRoutes from "./router/audio-categories";
import makeAudioCrudRoutes from "./router/audios";
import makeMbRoutes from "./router/meditation-background";
import makeMtRoutes from "./router/meditation-tune";
import makeSCRoutes from "./router/script-categories";
import makeCCRoutes from "./router/course-categories";
import makeEventRoutes from "./router/event";
import makeCourseRoutes from "./router/course";
import makeModuleRoutes from "./router/module";
import makeContentRoutes from "./router/content";
import makeScriptRoutes from "./router/script";
import makeSeriesRoutes from "./router/series";
import makeVideoRoutes from "./router/video";
import makeEbookRoutes from "./router/ebook";
import makeNotificationRoutes from "./router/notification";
import makeTagRoutes from "./router/tag";
import makeGoalRoutes from "./router/goal";
import makeFeelingRoutes from "./router/feeling";
import makeUserRoutes from "./router/user";
import makeUserPaidRoutes from "./router/user_paid";
import makeUserPromoRoutes from "./router/user_promo";
import makeUserFreeRoutes from "./router/user_free";
import makePromoRoutes from "./router/promocode";
import makeSeriesCategoriesRoutes from "./router/series-categories";
import makeVideoCategoriesRoutes from "./router/video-categories";
import VeeValidate from "vee-validate";

import { store } from "./Store";
import makeService from "./services/api";

import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'

import ToggleButton from 'vue-js-toggle-button'

import Snotify, { SnotifyPosition } from 'vue-snotify'

import ProgressBar from 'vuejs-progress-bar'

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
  }
}

window.$ = window.jQuery = require('jquery').noConflict(true);

Vue.use(ProgressBar)

import VueMoment from 'vue-moment';

// Vue.use(require('vue-moment'));

Vue.use(VueMoment, {
  timezone: 'America/Phoenix'
});

// import './plugins/moment.js';
Vue.use(ToggleButton)
Vue.use(Snotify, options)

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});

VeeValidate.Validator.extend('fileExtensionRequired', {
  getMessage: field => `The ${field} field is invalid.`,
  validate: (value,extensions) => {
    console.log('main ::::: ' , extensions);
    console.log('main ::::: ' , value.type.split('/')[1]);
    if (!value) {
      return false;
    }
    const allowedExtensions = extensions;
    const fileExtension = value.type.split('/')[1];
    return allowedExtensions.includes(fileExtension.toLowerCase());
  }
});

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)

Vue.config.productionTip = false


import VueTaggableSelect from "vue-taggable-select";
Vue.component('vue-taggable-select', VueTaggableSelect);


import moment from 'moment-timezone';
Vue.mixin({
  methods: {
    getFormattedTime(created_at,format = 'MMM DD YYYY, h:mm:ss a') {
      if(created_at == "" || created_at == null){
        return 'unknown';
      }
      return moment(created_at).tz('America/Phoenix').format(format);
    },
    // setTable() {
    //   setTimeout(() => {
    //     if (this.items.length > 0) {
    //       this.data_table = $("#audios_table").DataTable();
    //     }
    //   }, 2000);
    // },
  },
})

// import firebase from "firebase";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAQ11HECnLffAM3ZeJifGcZZmUNrN2uB-o",
  authDomain: "believe-b665d.firebaseapp.com",
  databaseURL: "https://believe-b665d-default-rtdb.firebaseio.com",
  projectId: "believe-b665d",
  storageBucket: "believe-b665d.appspot.com",
  messagingSenderId: "360199194045",
  appId: "1:360199194045:web:8c08f7d82623661304bed4",
  measurementId: "G-P6YWNQXV18",
};
initializeApp(firebaseConfig);




config.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeCrudRoutes({
      components: {
        ListingContainer
      },
      contentType
    })
  );
});

config_audios.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeAudioCrudRoutes({
      components: {
        AudioListingContainer
      },
      contentType
    })
  );
});

//meditation background
config_mb.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeMbRoutes({
      components: {
        MBListingContainer
      },
      contentType
    })
  );
});

//meditation tunes
config_mt.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeMtRoutes({
      components: {
        MTListingContainer
      },
      contentType
    })
  );
});



//script categories
config_script_categories.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeSCRoutes({
      components: {
        SCListingContainer
      },
      contentType
    })
  );
});

//series categories
config_series_categories.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeSeriesCategoriesRoutes({
      components: {
        SeriesCategoriesListingContainer
      },
      contentType
    })
  );
});



//video categories
config_video_categories.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeVideoCategoriesRoutes({
      components: {
        VideoCategoriesListingContainer
      },
      contentType
    })
  );
});



//script 
config_script.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeScriptRoutes({
      components: {
        ScriptListingContainer
      },
      contentType
    })
  );
});


//series 
config_series.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeSeriesRoutes({
      components: {
        SeriesListingContainer
      },
      contentType
    })
  );
});


//videos 
config_video.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeVideoRoutes({
      components: {
        VideoListingContainer
      },
      contentType
    })
  );
});



// ebook routes ::

config_ebook.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeEbookRoutes({
      components: {
        EbookListingContainer
      },
      contentType
    })
  );
});




// notification routes ::

config_notification.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeNotificationRoutes({
      components: {
        NotificationListingContainer
      },
      contentType
    })
  );
});




//tags routes

config_tag.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeTagRoutes({
      components: {
        TagListingContainer
      },
      contentType
    })
  );
});

//goal routes

config_goal.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeGoalRoutes({
      components: {
        GoalListingContainer
      },
      contentType
    })
  );
});

//feeling routes

config_feeling.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeFeelingRoutes({
      components: {
        FeelingListingContainer
      },
      contentType
    })
  );
});



//user routes

config_user.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeUserRoutes({
      components: {
        UserListingContainer
      },
      contentType
    })
  );
});


//subscribed user routes

config_user_paid.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeUserPaidRoutes({
      components: {
        UserPaidListingContainer
      },
      contentType
    })
  );
});


//promo user routes

config_user_promo.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeUserPromoRoutes({
      components: {
        UserPromoListingContainer
      },
      contentType
    })
  );
});


//free user routes

config_user_free.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeUserFreeRoutes({
      components: {
        UserFreeListingContainer
      },
      contentType
    })
  );
});



//promo routes

config_promo.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makePromoRoutes({
      components: {
        PromoListingContainer
      },
      contentType
    })
  );
});



// course category routes
config_course_categories.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeCCRoutes({
      components: {
        CCListingContainer
      },
      contentType
    })
  );
});




// event routes
config_event.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeEventRoutes({
      components: {
        EventListingContainer
      },
      contentType
    })
  );
});




config_course.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeCourseRoutes({
      components: {
        CourseListingContainer
      },
      contentType
    })
  );
});





// config_course.contentTypes.forEach(contentType => {
//   console.log('from main');
//   console.log(contentType);
//   store.registerModule(
//     contentType.name,
//     makeCrudModule({
//       service: makeService(contentType.endpoint,contentType.array_name??null)
//     })
//   );
//   router.addRoutes(
//     makeCourseRoutes({
//       components: {
//         CourseListingContainer
//       },
//       contentType
//     })
//   );
// });




// course routes
// config_course.contentTypes.forEach(contentType => {
//   store.registerModule(
//     contentType.name,
//     makeCrudModule({
//       service: makeService(contentType.endpoint,contentType.array_name??null)
//     })
//   );
//   router.addRoutes(
//     makeCourseRoutes({
//       components: {
//         CourseListingContainer
//       },
//       contentType
//     })
//   );
// });




// module routes
config_module.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeModuleRoutes({
      components: {
        ModuleListingContainer
      },
      contentType
    })
  );
});



// content routes
config_content.contentTypes.forEach(contentType => {
  store.registerModule(
    contentType.name,
    makeCrudModule({
      service: makeService(contentType.endpoint, contentType.array_name ?? null)
    })
  );
  router.addRoutes(
    makeContentRoutes({
      components: {
        ContentListingContainer
      },
      contentType
    })
  );
});


import api from "./config/api.js";

Vue.filter('capitalize', function(value) {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
});

// function for fetching data from api
Vue.prototype.$fetchDataReusableFunction = async function (endpoint) {
  // alert(endpoint);
  let result = await api.get(endpoint);
  if (result.status == 200) {
    return result?.data;
    //                 console.log("new audios data :::: ", result.data);
    // this.items = result?.data?.feelings;
    // this.items_total = result?.data?.total;
    // this.total_data = result?.data?.total_data;
    // if (this.items.length > 0) {
    //                     this.empty_data_error = "";
    //                     this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
    //                     this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
    //                 }
    //                 else {
    //                     this.empty_data_error = "Data Not Found!"
    //                 }

  }
}



Vue.use(require('vue-resource'));
Vue.component('ListingContainer', require('./components/Scripts/ScriptCategoriesListingContainer.vue').default);
Vue.component('InfiniteLoading', require('vue-infinite-loading'));


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

